import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import ItemsContent from 'components/ItemsContent';
import queryString from 'query-string';
import { CodeObjects } from 'models/ApplicationObjects.js';

function CodesPage({ location, authData, accountId, applications }) {
	const [codeObjects, setCodeObjects] = useState(null);
	const presetFilter = queryString.parse(location.search)['filter'];

	useEffect(() => {
		const _codeObjects = new CodeObjects();
		_codeObjects.updateObjects(authData, accountId).then(() => {
			setCodeObjects(_codeObjects);
		});
	}, [accountId]);

	return (
		<Layout location={location} 
				title={codeObjects?.objectsName} 
				authData={authData} 
				applications={applications}
		>
			{(codeObjects) ? (
				<ItemsContent 
					accountId={accountId}
					authData={authData}
					applicationObjects={codeObjects}
					presetFilter={presetFilter}
					applications={applications}
				/>
			) : null }
		</Layout>
	);
}
CodesPage.propTypes = {
	location: PropTypes.object,
	authData: PropTypes.object.isRequired,
	accountId: PropTypes.string,
	applications: PropTypes.object.isRequired
}
export default CodesPage
